import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  // Switch,
  Link
} from 'react-router-dom';
import {AnimatedSwitch} from 'react-router-transition';

import './App.css';

import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import Contact from './components/contact/contact';
import ReachBC from './components/project-detail/project-reachbc';
import Airway from './components/project-detail/project-airway';
import Fintel from './components/project-detail/project-fintel';
import TrustNews from './components/project-detail/project-trustnews';
import Gover from './components/project-detail/project-gover';
import GreenFill from './components/project-detail/project-greenfill';
import InClass from './components/project-detail/project-inclass';
import Estella from './components/project-detail/project-estella';
import Hfh from './components/project-detail/project-hfh';
import Archetype from './components/project-detail/project-archetype';
import ArtTiles from './components/project-detail/project-arttiles';
// import Preloading from './components/preloading-screen/preloading-screen'

// import logo from './logo.svg';

class App extends React.Component {

  // fake authentication Promise
  authenticate() {
    return new Promise(resolve => setTimeout(resolve, 3000)) // 3 seconds
  }

  componentDidMount() {
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele) {
        // fade out
        ele.classList.add('finish')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 1000)
      }
    })
  }

  render() {
    return (
      
      <div className='app'>
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header> */}

        {/* <Preloading/> */}
        
        <Router>
          <Navbar/>
          <FullSwitch/>
        </Router>

      </div>

    );
  }

}

export default App;

const FullSwitch = () => (
  <AnimatedSwitch
    atEnter={{ opacity: 0 }}
    atLeave={{ opacity: 0 }}
    atActive={{ opacity: 1 }}
    className="switch-wrapper"
  >
    <Route exact path='/' component={Projects}/>
    <Route path='/contact' component={Contact}/>
    <Route path='/reachbc' component={ReachBC}/>
    <Route path='/airway' component={Airway}/>
    <Route path='/fintel' component={Fintel}/>
    <Route path='/trustnews' component={TrustNews}/>
    <Route path='/gover' component={Gover}/>
    <Route path='/greenfill' component={GreenFill}/>
    <Route path='/inclass' component={InClass}/>
    <Route path='/estella' component={Estella}/>
    <Route path='/hfh' component={Hfh}/>
    <Route path='/archetype' component={Archetype}/>
    <Route path='/arttiles' component={ArtTiles}/>
  </AnimatedSwitch>
);

const Projects = () => (

  <div className="project-body">
    <div className="project-nav-bg"></div>
    
    <div className="project-wrapper wrapper-first">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/reachbc">
          <div className="project project-reachbc">
              <p className="project-number">01</p>
              <h1 className="project-name">HEALTH RESEARCH BC</h1>
              <p className="project-year">2023</p>
              <p className="project-description">A Health Research BC initiative, brings together researchers and volunteers to make it easy to participate in research.</p>
              <p className="project-scope">UX/UI design, UX research, User interviews, Usability testing</p>
          </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-airway">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/airway">
          <div className="project project-airway">
              <p className="project-number">02</p>
              <h1 className="project-name">AIRWAY</h1>
              <p className="project-year">2022</p>
              <p className="project-description">A charter booking platform designed specifically for traveling within the Canadian region.</p>
              <p className="project-scope">Branding, UX/UI design, Information architecture, Hi-fidelity prototyping, Business analysis</p>
          </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-fintel">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/fintel">
          <div className="project project-fintel">
              <p className="project-number">03</p>
              <h1 className="project-name">FINTEL CONNECT</h1>
              <p className="project-year">2021</p>
              <p className="project-description">Fintel Connect specializes in performance affiliate marketing tracking technology for the financial services and fintech sector.</p>
              <p className="project-scope">Branding, UX/UI design, UX research, Product design, SaaS</p>
          </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-trustnews">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/trustnews">
          <div className="project project-trustnews">
              <p className="project-number">04</p>
              <h1 className="project-name">TRUSTNEWS</h1>
              <p className="project-year">2020</p>
              <p className="project-description">Peer-reviewed news platform where curated articles meet audience-generated journalism.</p>
              <p className="project-scope">mobile app, web app, CMS, branding</p>
          </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-gover">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/gover">
          <div className="project project-gover">
              <p className="project-number">05</p>
              <h1 className="project-name">GOVER</h1>
              <p className="project-year">2019</p>
              <p className="project-description">Property inspection app with voice-to-text note and digital report sharing</p>
              <p className="project-scope">Mobile app, Web app, Corporate website, Branding</p>
          </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-greenfill">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/greenfill">
        <div className="project project-greenfill">
            <p className="project-number">06</p>
            <h1 className="project-name">GREENFILL</h1>
            <p className="project-year">2019</p>
            <p className="project-description">Zero-waste tracking with paperless receipts and zero-waste stores searching webapp</p>
            <p className="project-scope">Data visualization, Web app, Branding</p>
        </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-inclass">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/inclass">
        <div className="project project-inclass">
            <p className="project-number">07</p>
            <h1 className="project-name">IN CLASS</h1>
            <p className="project-year">2018</p>
            <p className="project-description">Class attendance app using QR code scanning to check-in</p>
            <p className="project-scope">Mobile app, Web app, Branding</p>
        </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-estella">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/estella">
        <div className="project project-estella">
            <p className="project-number">08</p>
            <h1 className="project-name">ESTELLA PLACE</h1>
            <p className="project-year">2017</p>
            <p className="project-description">Website for Estella shopping center</p>
            <p className="project-scope">corporate website, branding</p>
        </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-hfh">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/hfh">
        <div className="project project-hfh">
            <p className="project-number">09</p>
            <h1 className="project-name">HFH</h1>
            <p className="project-year">2017</p>
            <p className="project-description">Website for Hanoi French Hospital</p>
            <p className="project-scope">corporate website</p>
        </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-archetype">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/archetype">
        <div className="project project-archetype">
            <p className="project-number">10</p>
            <h1 className="project-name">ARCHETYPE</h1>
            <p className="project-year">2017</p>
            <p className="project-description">Website revamp for Archetype construction consultancies group</p>
            <p className="project-scope">corporate website</p>
        </div>
      </Link>
    </div>

    <div className="project-wrapper wrapper-arttiles">
      <Link style={{textDecoration:'none',color:'#FFF'}} to="/arttiles">
        <div className="project project-arttiles">
            <p className="project-number">11</p>
            <h1 className="project-name">M.A ART TILES</h1>
            <p className="project-year">2016</p>
            <p className="project-description">Commercial website for handmade tiles and traditional crafted furniture company</p>
            <p className="project-scope">commercial website</p>
        </div>
      </Link>
    </div>

    <Footer/>
  </div>

);
