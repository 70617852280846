import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import {Link} from 'react-router-dom';

import './project-detail.css';


import ReachBCLogo from '../assets/reachbc/reachbc-logo.svg'
import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';
import figmaIcon from '../assets/figma-icon.svg';

import cover from '../assets/reachbc/cover.png';
import uiSystems1 from '../assets/reachbc/reach1.png';
import uiSystems2 from '../assets/reachbc/reach2.png';
import uiSystems3 from '../assets/reachbc/reach3.png';
import uiSystems4 from '../assets/reachbc/reach4.png';

export default function ReachBC() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-reachbc-body scroll" : "project-detail-body project-reachbc-body" }`}>
      
      <HashLink smooth to="/reachbc/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <img src={ReachBCLogo} alt='Reach BC logo'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='Reach BC cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <p>REACH BC is the province's premier health research organization, a publicly funded platform which provides education on health research and related activities in BC. 
            REACH BC is governed by Clinical Trials BC at Michael Smith Health Research BC.
          </p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Solution Discovery</p>
          <p>UX Research</p>
          <p>User Interviewing</p>
          <p>UX/UI Design</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={figmaIcon} alt='figma-icon' height='40px'/>
        </div>
      </div>

      <div className="project-section project-ui">
        <img src={uiSystems1} alt="ui systems"/>
        <img src={uiSystems2} alt="ui systems"/>
        <img src={uiSystems3} alt="ui systems"/>
        <img src={uiSystems4} alt="ui systems"/>
      </div>

      <div className="project-section project-link">
        <h1>visit Reach BC <a href='https://reachbc.ca/' target="_blank">here</a></h1>
      </div>

      <div className="project-section end-section">
        <p>Thanh Nguyen © 2024 All Rights Reserved.</p>
      </div>

    </div>

  );

}