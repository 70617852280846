import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import {Link} from 'react-router-dom';
// import Lottie from "react-lottie";

import './project-detail.css';
// import LottieAnimation from './lottie.js';

import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';
import xdIcon from '../assets/adobe-xd-icon.svg';
import premiereIcon from '../assets/adobe-premiere-icon.svg';
import aeIcon from '../assets/adobe-ae-icon.svg';
import idIcon from '../assets/adobe-id-icon.svg';
import reactIcon from '../assets/react-js-icon.svg';
import sassIcon from '../assets/sass-icon.svg';

import cover from '../assets/gover/cover.png';
import painpointSolution from '../assets/gover/painpoints-solutions.jpg';
import persona1 from '../assets/gover/persona1.jpg';
import persona2 from '../assets/gover/persona2.jpg';
import persona3 from '../assets/gover/persona3.jpg';
import logoSketch from '../assets/gover/logo.jpg';
import iconSet from '../assets/gover/icon-set.jpg';
import typo from '../assets/gover/typo.jpg';
import buttons from '../assets/gover/buttons.jpg';
import cardsForms from '../assets/gover/cards-forms.jpg';
import addOns from '../assets/gover/add-ons.jpg';
import userstory from '../assets/gover/userstory.jpg';
import howWorks from '../assets/gover/how-we-works.jpg';
import userflow from '../assets/gover/userflow.jpg';
import wireframe from '../assets/gover/wireframe.jpg';
import prototype from '../assets/gover/prototype.jpg';
import mockupMain from '../assets/gover/mockup-main.jpg';
import mockupAndroid from '../assets/gover/mockup-android.jpg';
import mockupAnimation from '../assets/gover/mockup-animation.gif';
import mockupCorporate from '../assets/gover/mockup-corporate.gif';
import marketingProposal from '../assets/gover/marketing-proposal.jpg';
import marketingPages from '../assets/gover/marketing-pages.jpg';
import marketingFlyer from '../assets/gover/marketing-flyer.jpg';
import marketingCards from '../assets/gover/marketing-cards.jpg';
import GoverProposal from '../assets/gover/gover-proposal.pdf';
  
export default function Gover() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-gover-body scroll" : "project-detail-body project-gover-body" }`}>
      
      <HashLink smooth to="/gover/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <svg viewBox="0 0 1080 351">
            {/* <defs>
              <style>.cls-1{fill:#fff;}</style>
            </defs> */}
            <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
              <path class="cls-1" d="M351.17,0A108,108,0,1,0,459,108.2,108,108,0,0,0,351.17,0Zm-.38,161.76a54,54,0,1,1,54.1-53.91A54,54,0,0,1,350.79,161.76Z"/>
              <path class="cls-1" d="M665.91,0a27,27,0,0,0-24.13,15l0,0L586.34,134.06a1.5,1.5,0,0,1-2.73,0L529.18,17.32l-1.65-3.54h0a27,27,0,0,0-48.94,22.4L482,44l66,142,4.27,9a36,36,0,0,0,65.59-.33L622,186,689.82,39.68A27,27,0,0,0,665.91,0Z"/>
              <path class="cls-1" d="M1078.67,24.38A27,27,0,0,0,1045,6.94V6.89l-.48.15A143.82,143.82,0,0,0,999,31.55V27a27,27,0,0,0-54,0V141.08q-.06,2.88,0,5.79V189a27,27,0,0,0,54,0V145.82q0-1.8,0-3.6a90,90,0,0,1,61.2-83.48l1.14-.34.15,0,.89-.32A27,27,0,0,0,1078.67,24.38Z"/>
              <path class="cls-1" d="M208.11,176.75h0L182.77,151.6c-.23-.28-.46-.57-.7-.84a27.29,27.29,0,0,0-38-2.89,53.91,53.91,0,0,1-86.11-60l.06-.15c.25-.62.52-1.24.8-1.86.16-.36.33-.73.5-1.09s.31-.65.47-1c.35-.69.7-1.37,1.08-2.05a.59.59,0,0,0,0-.08,54,54,0,0,1,85.35-11.87h0c.51.52,1,1,1.59,1.46A54.15,54.15,0,0,1,160.74,95.8h0a27,27,0,0,0,52.65-12L213,81.93a94.3,94.3,0,0,0-10-25.82A107.61,107.61,0,0,0,138.08,4.25l-1.19-.33-1-.26A107.91,107.91,0,1,0,92.76,214.73l.79.11,1.35.18a107.6,107.6,0,0,0,63.54-11.52l11.49,11.4h0A27,27,0,0,0,209.3,178C208.92,177.57,208.52,177.16,208.11,176.75Z"/>
              <path class="cls-1" d="M165.35,250.66a27,27,0,0,0-6.26,9.72c-.07.21-.15.41-.22.62l0,.07a54,54,0,0,1-101.4.7c-.08-.25-.17-.51-.26-.77l-.21-.58A27,27,0,0,0,6.32,279.17l0,0,.06.18a109.13,109.13,0,0,0,10.24,21,107.89,107.89,0,0,0,192.67-20.17c.15-.34.28-.69.4-1,.2-.54.39-1.09.55-1.64a27,27,0,0,0-44.91-26.91Z"/>
              <path class="cls-1" d="M815.28,76.73l-12.44,9.69c-11.23,8.74-14.23,24.75-6.33,36.58a27,27,0,0,0,39.08,6.36l36.72-28.59,10.62-8.27.32-.25a45,45,0,0,0-1.08-71.83L881,19.57l-.08-.05a108,108,0,1,0,4.43,173.76h0c11.3-9.22,13.76-25.81,5-37.48a27,27,0,0,0-38.21-5.13l-.15-.19,0,0a54,54,0,1,1-15.66-93.65,1.8,1.8,0,0,1,.51,3.11Z"/>
            </g></g>
          </svg>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='Gover cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <p>Disputes between tenants and landlords regarding damage repairs are always
            inevitable. Home owners find hiring property home inspectors to be expensive
            but doing inspection themselves are also frustrating.</p>
          <br/>
          <p>Therefore, Gover was built as a multi-platform inspection app that enables home / business owners
            keep track of the condition of their properties.
          </p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Branding</p>
          <p>UX/UI Design</p>
          <p>Front-end Development</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={xdIcon} alt='xd-icon' width='40px'/>
          <img src={premiereIcon} alt='premiere-icon' width='40px'/>
          <img src={aeIcon} alt='after-effects-icon' width='40px'/>
          <img src={idIcon} alt='indesign-icon' width='40px'/>
          <img src={reactIcon} alt='react-js-icon' width='40px'/>
          <img src={sassIcon} alt='sass-icon' width='40px'/>
        </div>
      </div>

      <div className="project-section project-features">
        <h1>painpoints & solutions</h1>
        <img src={painpointSolution} alt="painpoints solutions"/>
      </div>

      <div className="project-section project-personas">
        <h1>personas</h1>
        <img src={persona1} alt="persona 1"/>
        <img src={persona2} alt="persona 2"/>
        <img src={persona3} alt="persona 3"/>
      </div>

      <div className="project-section project-branding">
        <h1>branding logo</h1>
        <img src={logoSketch} alt="logo sketching"/>
      </div>

      <div className="project-section project-ui">
        <h1>ui systems</h1>
        <img src={iconSet} alt="icon set"/>
        <img src={typo} alt="typography"/>
        <img src={buttons} alt="buttons"/>
        <img src={cardsForms} alt="cards and forms"/>
        <img src={addOns} alt="add-ons"/>
      </div>

      <div className="project-section project-ux">
        <h1>userstory</h1>
        <img src={userstory} alt="userstory"/>
      </div>

      <div className="project-section project-ux">
        <h1>how we works</h1>
        <img src={howWorks} alt="how we works"/>
      </div>

      <div className="project-section project-userflow">
        <h1>userflow</h1>
        <img src={userflow} alt="userflow"/>
      </div>

      <div className="project-section project-ux">
        <h1>wireframe</h1>
        <img src={wireframe} alt="wireframe"/>
      </div>

      <div className="project-section project-prototype">
        <h1>prototype</h1>
        <img src={prototype} alt="prototype"/>
      </div>

      <div className="project-section project-mockup">
        <h1>mockup</h1>
        <img src={mockupMain} alt="mockup main"/>
        <img src={mockupAndroid} alt="mockup android"/>
        <img src={mockupAnimation} alt="mockup animation"/>
        <img src={mockupCorporate} alt="mockup corporate"/>
      </div>

      <div className="project-section project-marketing">
        <h1>marketing materials</h1>
        <img src={marketingProposal} alt="marketing proposal"/>
        <img src={marketingPages} alt="marketing pages"/>
        <img src={marketingFlyer} alt="marketing flyer"/>
        <img src={marketingCards} alt="marketing cards"/>
      </div>

      <div className="project-section project-video">
        <h1>promo clip</h1>
        <div className="video-wrapper">
          <iframe src="https://www.youtube.com/embed/sfORmsUKtf8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

      <div className="project-section project-video">
        <h1>demo clip</h1>
        <div className="video-wrapper">
          <iframe src="https://www.youtube.com/embed/nSoGmeBGyP8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

      <div className="project-section project-proposal">
        <h1>read our case study <a href={GoverProposal} target="_blank">here</a></h1>
      </div>

      <div className="project-section end-section">
        <a href='https://gover-corporate.firebaseapp.com/' target="_blank">visit our website</a>
        <p>Thanh Nguyen © 2021 All Rights Reserved.</p>
      </div>

    </div>

  );

}