import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import Lottie from "react-lottie";

import './project-detail.css';
// import LottieAnimation from './lottie.js';

// import imgCover from '../assets/gover/gover-illustration.json';
import GreenFillLogo from '../assets/greenfill/greenfill-logo.svg'
import aiIcon from '../assets/adobe-illu-icon.svg';
import xdIcon from '../assets/adobe-xd-icon.svg';
import aeIcon from '../assets/adobe-ae-icon.svg';
import idIcon from '../assets/adobe-id-icon.svg';
import invisionIcon from '../assets/invision-icon.svg';
import html5Icon from '../assets/html5-icon.svg';
import sassIcon from '../assets/sass-icon.svg';

import cover from '../assets/greenfill/cover.gif';
import painpointSolution from '../assets/greenfill/about.jpg';
import persona1 from '../assets/greenfill/persona1.jpg';
import persona2 from '../assets/greenfill/persona2.jpg';
import persona3 from '../assets/greenfill/persona3.jpg';
import logoSketch from '../assets/greenfill/logo.jpg';
import colorPalette from '../assets/greenfill/colors.jpg';
import iconSet from '../assets/greenfill/icons.jpg';
import typo from '../assets/greenfill/typo.jpg';
import UIcomponents from '../assets/greenfill/ui-components.jpg';
import siteMap from '../assets/greenfill/sitemap.jpg';
import mockupHomepage from '../assets/greenfill/mockup-homepage.jpg';
import mockupHomepageRes from '../assets/greenfill/mockup-homepage-res.jpg';
import mockupStoreSearch from '../assets/greenfill/mockup-storesearch.jpg';
import mockupTips from '../assets/greenfill/mockup-tips.jpg';
import mockupTracker from '../assets/greenfill/mockup-tracker.jpg';
import mockupAboutContact from '../assets/greenfill/mockup-aboutcontact.jpg';
import marketingProposal1 from '../assets/greenfill/marketing-proposal-1.jpg';
import marketingProposal2 from '../assets/greenfill/marketing-proposal-2.jpg';
import marketingProposal3 from '../assets/greenfill/marketing-proposal-3.jpg';
import marketingPoster from '../assets/greenfill/poster.jpg';
import marketingFlyer from '../assets/greenfill/flyer.jpg';
import GreenfillProposal from '../assets/greenfill/greenfill-proposal.pdf';

export default function Greenfill() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-greenfill-body scroll" : "project-detail-body project-greenfill-body" }`}>
      
      <HashLink smooth to="/greenfill/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <img src={GreenFillLogo} alt='GreenFill logo'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='GreenFill cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <h1>zero waste web app</h1>
          <p>Greenfill is web app that promotes zero waste lifestyle by making it easy and fun.</p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Branding</p>
          <p>UX/UI Design</p>
          <p>Front-end Development</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={xdIcon} alt='xd-icon' width='40px'/>
          <img src={aeIcon} alt='after-effects-icon' width='40px'/>
          <img src={idIcon} alt='indesign-icon' width='40px'/>
          <img src={invisionIcon} alt='invision-icon' width='40px'/>
          <img src={html5Icon} alt='html5-icon' width='40px'/>
          <img src={sassIcon} alt='sass-icon' width='40px'/>
        </div>
      </div>

      <div className="project-section project-features">
        <h1>about the project</h1>
        <img src={painpointSolution} alt="painpoints solutions"/>
      </div>

      <div className="project-section project-personas">
        <h1>personas</h1>
        <img src={persona1} alt="persona 1"/>
        <img src={persona2} alt="persona 2"/>
        <img src={persona3} alt="persona 3"/>
      </div>

      <div className="project-section project-branding">
        <h1>branding identity</h1>
        <img src={logoSketch} alt="logo sketching"/>
        <img src={colorPalette} alt="color palette"/>
        <img src={iconSet} alt="icon set"/>
        <img src={typo} alt="typography"/>
      </div>

      <div className="project-section project-ui">
        <h1>UI components</h1>
        <img src={UIcomponents} alt="UI components"/>
      </div>

      <div className="project-section project-sitemap">
        <h1>sitemap</h1>
        <img src={siteMap} alt="sitemap"/>
      </div>

      <div className="project-section project-mockup-1">
        <h1>mockup</h1>
        <img src={mockupHomepage} alt="mockup homepage"/>
        <img src={mockupHomepageRes} alt="mockup homepage responsive"/>
      </div>

      <div className="project-section project-mockup-2">
        <img src={mockupStoreSearch} alt="mockup store search"/>
      </div>

      <div className="project-section project-mockup-3">
        <img src={mockupTips} alt="mockup tips"/>
      </div>

      <div className="project-section project-mockup-4">
        <img src={mockupTracker} alt="mockup tracker"/>
      </div>

      <div className="project-section project-mockup-5">
        <img src={mockupAboutContact} alt="mockup about contact"/>
      </div>

      <div className="project-section project-marketing">
        <h1>marketing materials</h1>
        <img src={marketingProposal1} alt="marketing proposal 1"/>
        <img src={marketingProposal2} alt="marketing proposal 2"/>
        <img src={marketingProposal3} alt="marketing proposal 3"/>
        <img src={marketingPoster} alt="marketing poster"/>
        <img src={marketingFlyer} alt="marketing flyer"/>
      </div>

      <div className="project-section project-proposal">
        <h1>read our case study <a href={GreenfillProposal} target="_blank">here</a></h1>
      </div>

      <div className="project-section end-section">
        <p>Thanh Nguyen © 2021 All Rights Reserved.</p>
      </div>

    </div>

  );

}