import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import {Link} from 'react-router-dom';

import './project-detail.css';


import FintelLogo from '../assets/fintel/fintel-logo.svg'
import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';
import aeIcon from '../assets/adobe-ae-icon.svg';
import figmaIcon from '../assets/figma-icon.svg';

import cover from '../assets/fintel/banner.gif';
import uiSystems1 from '../assets/fintel/fintel1.png';
import uiSystems2 from '../assets/fintel/fintel2.png';
import uiSystems3 from '../assets/fintel/fintel3.png';
import uiSystems4 from '../assets/fintel/fintel4.jpg';

export default function Fintel() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-fintel-body scroll" : "project-detail-body project-fintel-body" }`}>
      
      <HashLink smooth to="/fintel/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <img src={FintelLogo} alt='Fintel logo'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='Fintel cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <p>Fintel Connect is a bespoke performance affiliate marketing network which connects publishers and merchants within the financial services space.
            This sophisticated fintech software platform counts many major banks and financial institutions as clients.
            It was a true pleasure to be a part of a dedicate cross-functioning team that brought Fintel Connect to life.
          </p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Web and Mobile Development</p>
          <p>UX/UI Design</p>
          <p>Rapid Prototyping</p>
          <p>Graphic Design</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={aeIcon} alt='after-effects-icon' width='40px'/>
          <img src={figmaIcon} alt='figma-icon' height='40px'/>
        </div>
      </div>

      <div className="project-section project-ui">
        <img src={uiSystems1} alt="ui systems"/>
        <img src={uiSystems2} alt="ui systems"/>
        <img src={uiSystems3} alt="ui systems"/>
        <img src={uiSystems4} alt="ui systems"/>
      </div>

      <div className="project-section project-link">
        <h1>visit Fintel Connect <a href='https://www.fintelconnect.com/' target="_blank">here</a></h1>
      </div>

      <div className="project-section end-section">
        <p>Thanh Nguyen © 2024 All Rights Reserved.</p>
      </div>

    </div>

  );

}