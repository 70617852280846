import React from 'react';

import './contact.css';

import Footer from '../footer/footer';

function Contact() {
    return (
        
      <div className="contact-body">

          {/* <div className="contact-intro">
            <h1>hi, i am </h1>
            <h1 className="contact-name">thanh nguyen</h1>
          </div> */}

          <p className="contact-resume">hi, i am <span className='span-hl'>thanh nguyen</span>. i’m a <span className='span-hl'>ux/ui designer </span>
            with experience in graphic design and front-end development which allows me to be both technical and creative in my approach. knowledge in all 
            aspects of the design process, from <span className='span-hl'>creating user stories, user flows, wireframing, mockups,</span> to <span className='span-hl'>
            prototyping</span> and <span className='span-hl'>design handoffs</span>. i love finding digital solutions through corporate design thinking with coding. 
            i’m also passionate about video production.</p>

          <div className="contact-callto">
            <p>say hello <span className="contact-email">nguyenmt.246@gmail.com</span>
              {/* <svg><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/></svg> */}
            </p>
          </div>

          {/* <div className="contact-link">
            <h2>check out <span>my resume</span></h2>
          </div> */}

          <Footer/>

      </div>

    );
  }
  
export default Contact;