import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import {Link} from 'react-router-dom';

import './project-detail.css';

import hfhLogo from '../assets/hfh/hfh-logo.svg'
import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';
import sketchIcon from '../assets/sketch-icon.svg';

import cover from '../assets/hfh/cover.png';
import UI1 from '../assets/hfh/ui1.jpg';
import UI2 from '../assets/hfh/ui2.jpg';
import UI3 from '../assets/hfh/ui3.jpg';
import UI4 from '../assets/hfh/ui4.jpg';
import siteMap from '../assets/hfh/sitemap.jpg';
import mockup1 from '../assets/hfh/mockup1.jpg';
import mockup2 from '../assets/hfh/mockup2.jpg';
import mockup3 from '../assets/hfh/mockup3.jpg';
import mockup4 from '../assets/hfh/mockup4.jpg';
import mockup5 from '../assets/hfh/mockup5.jpg';
import mockup6 from '../assets/hfh/mockup6.jpg';

export default function Hfh() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-hfh-body scroll" : "project-detail-body project-hfh-body" }`}>

      <HashLink smooth to="/hfh/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <img src={hfhLogo} alt='HFH logo'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='HFH cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <p>The project for Hanoi French Hospital is to bring the most current and optimized
            user experience for clients that use the hospital's website. In other to achieve that,
            we prioritize a mobile first design, updated navigation and overall user-centric
            experience practice.
          </p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Website UX/UI Design</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={sketchIcon} alt='sketch-icon' width='40px'/>
        </div>
      </div>

      <div className="project-section project-ui">
        <h1>ui style guide</h1>
        <img src={UI1} alt='ui styleguide'/>
        <img src={UI2} alt='ui styleguide'/>
        <img src={UI3} alt='ui styleguide'/>
        <img src={UI4} alt='ui styleguide'/>
      </div>

      <div className="project-section project-sitemap">
        <h1>sitemap</h1>
        <img src={siteMap} alt='sitemap'/>
      </div>

      <div className="project-section project-mockup">
        <h1>website mockup</h1>
        <img src={mockup1} alt='mockup'/>
        <img src={mockup2} alt='mockup'/>
        <img src={mockup3} alt='mockup'/>
        <img src={mockup4} alt='mockup'/>
        <img src={mockup5} alt='mockup'/>
        <img src={mockup6} alt='mockup'/>
      </div>

      <div className="project-section end-section">
        <a href='https://www.hfh.com.vn' target="_blank">visit our website</a>
        <p>Thanh Nguyen © 2021 All Rights Reserved.</p>
      </div>
      
    </div>

  );
  
}