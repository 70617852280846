import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import {Link} from 'react-router-dom';

import './project-detail.css';

import archetypeLogo from '../assets/archetype/archetype-logo.svg'
import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';
import sketchIcon from '../assets/sketch-icon.svg';

import cover from '../assets/archetype/cover.jpg';
import homePage from '../assets/archetype/homepage.jpg';
import companyProfile from '../assets/archetype/company-profile.jpg';
import companyPeople from '../assets/archetype/people.jpg';
import partnership from '../assets/archetype/partnership.jpg';
import portfolio from '../assets/archetype/company-portfolio.jpg';
import career from '../assets/archetype/career.jpg';
import news from '../assets/archetype/news.jpg';
import contact from '../assets/archetype/contact.jpg';

export default function Archetype() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-archetype-body scroll" : "project-detail-body project-archetype-body" }`}>

      <HashLink smooth to="/archetype/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <img src={archetypeLogo} alt='Archetype logo'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='Archetype cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <p>Archetype old website is outdated which made it unable to be viewed on mobile devices.
            The project goal is to create a new look for the website that maintains its branding, yet
            responsive for mobile view.
          </p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Website UX/UI Revamp</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={sketchIcon} alt='sketch-icon' width='40px'/>
        </div>
      </div>

      <div className="project-section project-mockup">
        <h1>homepage</h1>
        <img src={homePage} alt='homepage'/>
      </div>

      <div className="project-section project-mockup">
        <h1>company profile page</h1>
        <img src={companyProfile} alt='company profile'/>
      </div>

      <div className="project-section project-mockup">
        <h1>people page</h1>
        <img src={companyPeople} alt='company people'/>
      </div>

      <div className="project-section project-mockup">
        <h1>partnership page</h1>
        <img src={partnership} alt='partnership'/>
      </div>

      <div className="project-section project-mockup">
        <h1>company portfolio page</h1>
        <img src={portfolio} alt='company portfolio'/>
      </div>

      <div className="project-section project-mockup">
        <h1>careers page</h1>
        <img src={career} alt='career'/>
      </div>

      <div className="project-section project-mockup">
        <h1>news page</h1>
        <img src={news} alt='news'/>
      </div>

      <div className="project-section project-mockup">
        <h1>contact page</h1>
        <img src={contact} alt='contact'/>
      </div>

      <div className="project-section end-section">
        <a href='https://www.archetype-group.com/' target="_blank">visit our website</a>
        <p>Thanh Nguyen © 2021 All Rights Reserved.</p>
      </div>
      
    </div>

  );
  
}