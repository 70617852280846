import React, {useState, useEffect} from 'react';
import {Link,NavLink} from 'react-router-dom';

import DrawerToggleBtn from '../burger-menu/drawerToggleBtn';
import './navbar.css';

export default function Navbar() {

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
          setScroll(window.scrollY > '64');
        });
    }, []); 

    return(

        <header>
            <nav className={`${scroll ? "navigation-bar scroll" : "navigation-bar" }`}>
                <div className="logo">
                    <Link style={{textDecoration:'none',color:'#FFF'}} to="/">
                        <h1>tn<span>.</span></h1>
                    </Link>
                </div>
                <div className="navbar-items">
                    <ul>
                        <NavLink exact activeClassName="navbar-item-active" className=" " to="/">
                            <li><a href="/">works</a></li>
                        </NavLink>
                        <NavLink activeClassName="navbar-item-active" className=" " to="/contact">
                            <li><a href="/">contact</a></li>
                        </NavLink>
                    </ul>
                </div>
                <div className="hamburger-icon">
                    <DrawerToggleBtn/>
                </div>
            </nav>
        </header>

    )

}