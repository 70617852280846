import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import Lottie from "react-lottie";
// import {Link} from 'react-router-dom';

import './project-detail.css';
// import LottieAnimation from './lottie.js';

import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';
import xdIcon from '../assets/adobe-xd-icon.svg';
import invisionIcon from '../assets/invision-icon.svg';
import html5Icon from '../assets/html5-icon.svg';
import sassIcon from '../assets/sass-icon.svg';

import cover from '../assets/inclass/cover.png';
import objectives from '../assets/inclass/objectives.jpg';
import logoSketch from '../assets/inclass/logo.jpg';
import colorTypo from '../assets/inclass/color-typo.jpg';
import uiComponents from '../assets/inclass/ui-components.jpg';
import userStory from '../assets/inclass/user-story.jpg';
import userJourney from '../assets/inclass/user-journey.jpg';
import userflow from '../assets/inclass/userflow.jpg';
import prototypeWeb1 from '../assets/inclass/prototype-web-1.jpg';
import prototypeWeb2 from '../assets/inclass/prototype-web-2.jpg';
import prototypeWeb3 from '../assets/inclass/prototype-web-3.jpg';
import prototypeWeb4 from '../assets/inclass/prototype-web-4.jpg';
import prototypeMobile from '../assets/inclass/prototype-mobile.jpg';
import prototypeCorporate from '../assets/inclass/prototype-corporate.jpg';
import mockupMobile from '../assets/inclass/mockup-mobile.gif';
import mockupWeb from '../assets/inclass/mockup-web.jpg';
import mockupDevices from '../assets/inclass/mockup-devices.jpg';
import InclassProposal from '../assets/inclass/inclass-proposal.pdf';

export default function Inclass() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-inclass-body scroll" : "project-detail-body project-inclass-body" }`}>
      
      <HashLink smooth to="/inclass/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1558.4 288">
            {/* <defs><style>.cls-1{fill:#0a2239;}</style></defs> */}
            <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <rect class="cls-1" x="395.96" y="76.94" width="30.91" height="206.11"/>
              <path class="cls-1" d="M580.64,72.82q-30.51,0-63.9,18.55V76.94h-30.5V283.06h30.91V115.69L525,112q7.82-3.72,22.46-7.63a107.77,107.77,0,0,1,27.83-3.92q27.62,0,36.07,15.87t8.45,58.75v108h30.5V174.23q0-56.88-14.22-79.15T580.64,72.82Z"/>
              <path class="cls-1" d="M796.63,121.46q15.66,0,48.23,3.31l1.65-43.7-11.13-2.48Q802,72,780.56,72q-45.76,0-66.37,25.35t-20.61,82.24q0,56.88,20,82.65T779.32,288q23.08,0,67.19-8.66l-1.65-44.1-47.81,3.3q-28.86,0-38.13-12.58t-9.28-46.37q0-33.79,9.28-46T796.63,121.46Z"/>
              <rect class="cls-1" x="889.64" width="55.22" height="283.46"/>
              <path class="cls-1" d="M1159.79,240.8c-1.64-2.61-2.75-7.21-3.29-13.81V141.25q0-36.27-17.52-52.76T1081.68,72q-39.78,0-83.89,12l1.65,38.34,77.5-3.3q12.76,0,18.55,4.74t5.77,17.52V154l-44.11,3.3q-36.69,2.46-54.41,16.28T985,220.4q0,67.61,63.9,67.6,30.91,0,60.59-14.43a75.53,75.53,0,0,0,25.35,11.54q12.59,2.89,33.6,2.89l1.65-41.63Q1162.27,244.72,1159.79,240.8Zm-58.53-4.32-6.18,1.65a133.9,133.9,0,0,1-34.22,4.94q-20.19,0-20.2-22.67t23.09-24.74l37.51-3.29Z"/>
              <path class="cls-1" d="M1302.62,157.53q-33.18-6-41.22-9.06t-8-11.13c0-5.36,2-9.28,6-11.75s12.78-3.71,26.39-3.71,37.43,2.2,71.51,6.59l.83-45.75-13.61-2.47q-42-7.83-66.36-7.84-35.05,0-57.3,16.69t-22.26,49.47q0,32.77,15.66,45.55t47.62,18.14q31.93,5.36,39.57,9.07c5.08,2.47,7.62,6.33,7.62,11.54s-2.41,9.14-7.21,11.75-13.88,3.92-27.21,3.92-37-2.2-71.1-6.6l-1.65,46.17,13.19,2.47q38.74,7.43,66,7.42,82.86,0,82.86-66.78,0-30.5-14-44.11T1302.62,157.53Z"/>
              <path class="cls-1" d="M1544.38,177.11q-14-13.59-47.2-19.58T1456,148.47q-8-3.1-8-11.13c0-5.36,2-9.28,6-11.75s12.78-3.71,26.38-3.71,37.44,2.2,71.52,6.59l.83-45.75L1539,80.25q-42-7.83-66.36-7.84-35.06,0-57.3,16.69t-22.26,49.47q0,32.77,15.66,45.55t47.61,18.14q32,5.36,39.58,9.07c5.08,2.47,7.62,6.33,7.62,11.54s-2.41,9.14-7.21,11.75-13.88,3.92-27.21,3.92-37-2.2-71.1-6.6l-1.65,46.17,13.19,2.47q38.75,7.43,66,7.42,82.86,0,82.86-66.78Q1558.4,190.72,1544.38,177.11Z"/>
              <path class="cls-1" d="M144,0A144,144,0,1,0,288,144,144,144,0,0,0,144,0ZM116.64,225,52.8,161.2l27.36-27.36,36.48,36.48,91.2-91.2,27.36,27.36Z"/>
            </g></g>
          </svg>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='InClass cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <h1>attendance checking app</h1>
          <p>InClass offers a multi-platform solution to digitize the attendance process. 
          Our product eliminate the manual recording of attendance.</p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Branding</p>
          <p>UX/UI Design</p>
          <p>Front-end Development</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={xdIcon} alt='xd-icon' width='40px'/>
          <img src={invisionIcon} alt='invision-icon' width='40px'/>
          <img src={html5Icon} alt='html5-icon' width='40px'/>
          <img src={sassIcon} alt='sass-icon' width='40px'/>
        </div>
      </div>

      <div className="project-section project-features">
        <h1>about the project</h1>
        <img src={objectives} alt="objectives"/>
      </div>

      <div className="project-section project-branding">
        <h1>ui elements</h1>
        <img src={logoSketch} alt="logo sketching"/>
        <img src={colorTypo} alt="color and typography"/>
        <img src={uiComponents} alt="ui components"/>
      </div>

      <div className="project-section project-ux">
        <h1>ux documentation</h1>
        <img src={userStory} alt="user story"/>
        <img src={userJourney} alt="user journey"/>
        <img src={userflow} alt="userflow"/>
      </div>

      <div className="project-section project-prototype">
        <h1>prototype walkthrough</h1>
        <img src={prototypeWeb1} alt="prototype web"/>
        <img src={prototypeWeb2} alt="prototype web"/>
        <img src={prototypeWeb3} alt="prototype web"/>
        <img src={prototypeWeb4} alt="prototype web"/>
        <img src={prototypeMobile} alt="prototype mobile"/>
        <img src={prototypeCorporate} alt="prototype corporate"/>
      </div>

      <div className="project-section project-mockup">
        <h1>mockup</h1>
        <img src={mockupMobile} alt="mockup mobile"/>
        <img src={mockupWeb} alt="mockup web"/>
        <img src={mockupDevices} alt="mockup devices"/>
      </div>

      <div className="project-section project-video">
        <h1>demo clip</h1>
        <div className="video-wrapper">
          <iframe src="https://www.youtube.com/embed/_eTR7iqKq80" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

      <div className="project-section project-proposal">
        <h1>read our case study <a href={InclassProposal} target="_blank">here</a></h1>
      </div>

      <div className="project-section end-section">
        <p>Thanh Nguyen © 2021 All Rights Reserved.</p>
      </div>

    </div>

  );
  
}