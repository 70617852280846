import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import {Link} from 'react-router-dom';

import './project-detail.css';

import estellaLogo from '../assets/estella/estella-logo.svg'
import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';
import idIcon from '../assets/adobe-id-icon.svg';
import sketchIcon from '../assets/sketch-icon.svg';

import cover from '../assets/estella/cover.png';
import logoSketch from '../assets/estella/logo.jpg';
import colorTypo from '../assets/estella/color-typo.jpg';
import sitemap from '../assets/estella/sitemap.jpg';
import visual1 from '../assets/estella/visual1.jpg';
import visual2 from '../assets/estella/visual2.jpg';
import visual3 from '../assets/estella/visual3.jpg';
import visual4 from '../assets/estella/visual4.jpg';
import visual5 from '../assets/estella/visual5.jpg';
import visual6 from '../assets/estella/visual6.jpg';
import visual7a from '../assets/estella/visual7a.jpg';
import visual7b from '../assets/estella/visual7b.jpg';
import folder1 from '../assets/estella/folder1.jpg';
import folder2 from '../assets/estella/folder2.jpg';
import brochure1 from '../assets/estella/brochure1.jpg';
import brochure2 from '../assets/estella/brochure2.jpg';
import brochure3 from '../assets/estella/brochure3.jpg';
import leaflet1 from '../assets/estella/leaflet1.jpg';
import leaflet2 from '../assets/estella/leaflet2.jpg';

export default function Estella() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-estella-body scroll" : "project-detail-body project-estella-body" }`}>

      <HashLink smooth to="/estella/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <img src={estellaLogo} alt='Estella logo'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='Estella cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <p>Estella Place is an upcoming mall that is soon to be opened in Ho Chi Minh
            city. Our branding identity, marketing materials and website are parts of
            the preparation for the mall's grand-opening.
          </p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Branding</p>
          <p>Print</p>
          <p>Website UX/UI Design</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={idIcon} alt='indesign-icon' width='40px'/>
          <img src={sketchIcon} alt='sketch-icon' width='40px'/>
        </div>
      </div>

      <div className="project-section project-branding">
        <h1>branding identity</h1>
        <img src={logoSketch} alt="logo"/>
        <img src={colorTypo} alt="color and typo"/>
      </div>

      <div className="project-section project-sitemap">
        <h1>sitemap</h1>
        <img src={sitemap} alt="sitemap"/>
      </div>

      <div className="project-section project-website">
        <h1>website visual design</h1>
        <img src={visual1} alt="web screen"/>
        <img src={visual2} alt="web screen"/>
        <img src={visual3} alt="web screen"/>
        <img src={visual4} alt="web screen"/>
        <img src={visual5} alt="web screen"/>
        <img src={visual6} alt="web screen"/>
        <img src={visual7a} alt="web screen"/>
        <img src={visual7b} alt="web screen"/>
      </div>

      <div className="project-section project-print">
        <h1>print materials</h1>
        <img src={folder1} alt="folder outside"/>
        <img src={folder2} alt="folder inside"/>
        <img src={brochure1} alt="brochure"/>
        <img src={brochure2} alt="brochure"/>
        <img src={brochure3} alt="brochure"/>
        <img src={leaflet1} alt="leaflet front"/>
        <img src={leaflet2} alt="leaflet back"/>
      </div>

      <div className="project-section end-section">
        <p>Thanh Nguyen © 2021 All Rights Reserved.</p>
      </div>
      
    </div>

  );
  
}