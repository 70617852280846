import React, { useState } from 'react';
import {Link} from 'react-router-dom';

import './drawerToggleBtn.css';

import Resume from '../assets/tn-resume.pdf'; 

export default function DrawerToggleBtn() {

    const [open, setOpen] = useState(false)
    // const [toggle, setToggle] = useState(false)

    function openMenuBar() {
        setOpen(!open)
    }

    return (

        <div className="toggle-btn">
            <button className={`${open ? "changed-btn" : " "}`} onClick={() => openMenuBar()}>
                <div className="toggle-btn__line"></div>
                <div className="toggle-btn__line"></div>
                <div className="toggle-btn__line"></div>
            </button>
            <nav className="side-drawer">
                <div className="side-drawer-items">
                    <ul>
                        <li><Link onClick= {openMenuBar} to="/"><a href="/">works</a></Link></li>
                        <li><Link onClick= {openMenuBar} to="/contact"><a href="/">contact</a></Link></li>
                    </ul>
                </div>
                <a className="side-drawer-resume" href={Resume} target="_blank">my resume</a>
                <div className="side-drawer-social">
                    <a href='https://www.linkedin.com/in/thanhmng/' target="_blank">
                        <button className="side-drawer-linkedin">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 
                                0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
                            </svg>
                        </button>
                    </a>
                    
                    <a href='https://www.behance.net/thanhmng' target="_blank">
                        <button className="side-drawer-behance">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 
                                1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 
                                0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 
                                0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"/>
                            </svg>
                        </button>
                    </a>

                    <a href='https://www.dribbble.com/thanhmng' target="_blank">
                        <button className="side-drawer-dribbble">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M12 0c-6.628 0-12 5.373-12 12s5.372 12 12 12 12-5.373 12-12-5.372-12-12-12zm9.885 
                                11.441c-2.575-.422-4.943-.445-7.103-.073-.244-.563-.497-1.125-.767-1.68 2.31-1 4.165-2.358 
                                5.548-4.082 1.35 1.594 2.197 3.619 2.322 5.835zm-3.842-7.282c-1.205 1.554-2.868 2.783-4.986 
                                3.68-1.016-1.861-2.178-3.676-3.488-5.438.779-.197 1.591-.314 2.431-.314 2.275 0 4.368.779 6.043 
                                2.072zm-10.516-.993c1.331 1.742 2.511 3.538 3.537 5.381-2.43.715-5.331 1.082-8.684 1.105.692-2.835 2.601-5.193 5.147-6.486zm-5.44 
                                8.834l.013-.256c3.849-.005 7.169-.448 9.95-1.322.233.475.456.952.67 1.432-3.38 1.057-6.165 3.222-8.337 
                                6.48-1.432-1.719-2.296-3.927-2.296-6.334zm3.829 7.81c1.969-3.088 4.482-5.098 7.598-6.027.928 2.42 1.609 4.91 2.043 7.46-3.349 
                                1.291-6.953.666-9.641-1.433zm11.586.43c-.438-2.353-1.08-4.653-1.92-6.897 1.876-.265 3.94-.196 6.199.196-.437 2.786-2.028 5.192-4.279 6.701z"/>
                            </svg>
                        </button>
                    </a>
                </div>
            </nav>
        </div>
        
    )
}