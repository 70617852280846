import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import {Link} from 'react-router-dom';

import './project-detail.css';


import TrustNewsLogo from '../assets/trustnews/trustnews-logo.svg'
import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';
import xdIcon from '../assets/adobe-xd-icon.svg';
import reactIcon from '../assets/react-js-icon.svg';

import cover from '../assets/trustnews/cover.png';
import uiSystems1 from '../assets/trustnews/ui1.jpg';
import uiSystems2 from '../assets/trustnews/ui2.jpg';
import uiSystems3 from '../assets/trustnews/ui3.jpg';
import sitemap from '../assets/trustnews/sitemap.jpg';
import frontend1 from '../assets/trustnews/frontend1.jpg';
import frontend2 from '../assets/trustnews/frontend2.jpg';
import frontend3 from '../assets/trustnews/frontend3.jpg';
import frontend4 from '../assets/trustnews/frontend4.jpg';
import frontend5 from '../assets/trustnews/frontend5.jpg';
import frontend6 from '../assets/trustnews/frontend6.jpg';
import frontend7 from '../assets/trustnews/frontend7.jpg';
import cms from '../assets/trustnews/cms.jpg';
import mobile from '../assets/trustnews/mobile.jpg';

export default function Trustnews() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-trustnews-body scroll" : "project-detail-body project-trustnews-body" }`}>
      
      <HashLink smooth to="/trustnews/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <img src={TrustNewsLogo} alt='TrustNews logo'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='TrustNews cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <p>Trust News provides reliable and trustworthy news by enabling the exchange of value directly between journalists
            and readers through blockchain technology. This approach empowers journalists to continue their work and
            readers to receive trustworthy news with strong transparency.
          </p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Branding</p>
          <p>UX/UI Design</p>
          <p>Front-end Development</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={xdIcon} alt='xd-icon' width='40px'/>
          <img src={reactIcon} alt='react-js-icon' width='40px'/>
        </div>
      </div>

      <div className="project-section project-ui">
        <h1>ui systems</h1>
        <img src={uiSystems1} alt="ui systems"/>
        <img src={uiSystems2} alt="ui systems"/>
        <img src={uiSystems3} alt="ui systems"/>
      </div>

      <div className="project-section project-ux">
        <h1>ux documentation</h1>
        <img src={sitemap} alt="sitemap"/>
      </div>

      <div className="project-section project-frontend">
        <h1>frontend website</h1>
        <img src={frontend1} alt="frontend"/>
        <img src={frontend2} alt="frontend"/>
        <img src={frontend3} alt="frontend"/>
        <img src={frontend4} alt="frontend"/>
        <img src={frontend5} alt="frontend"/>
        <img src={frontend6} alt="frontend"/>
        <img src={frontend7} alt="frontend"/>
      </div>

      <div className="project-section project-mobile">
        <h1>mobile app</h1>
        <img src={mobile} alt="mobile"/>
      </div>

      <div className="project-section project-cms">
        <h1>desktop cms</h1>
        <img src={cms} alt="cms"/>
      </div>

      <div className="project-section end-section">
        <p>Thanh Nguyen © 2021 All Rights Reserved.</p>
      </div>

    </div>

  );

}