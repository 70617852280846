import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import {Link} from 'react-router-dom';

import './project-detail.css';


import AirwayLogo from '../assets/airway/airway-logo.svg'
import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';
import aeIcon from '../assets/adobe-ae-icon.svg';
import figmaIcon from '../assets/figma-icon.svg';

import cover from '../assets/airway/cover.png';
import uiSystems1 from '../assets/airway/air1.png';
import uiSystems2 from '../assets/airway/air2.png';
import uiSystems3 from '../assets/airway/air3.png';
import uiSystems4 from '../assets/airway/air4.png';
import uiSystems5 from '../assets/airway/air5.png';
import uiSystems6 from '../assets/airway/air6.png';

export default function Airway() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-airway-body scroll" : "project-detail-body project-airway-body" }`}>
      
      <HashLink smooth to="/airway/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>

      <Fade left>
        <div className="project-logo" id='project-landing'>
          <img src={AirwayLogo} alt='Airway logo'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='Airway cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <p>Airway (Aviation sector client's name withheld due to confidentiality restrictions)
            is a charter booking platform designed specifically for traveling within the Canadian region.
            The platform gives users the ability to easily and simply plan and create a trip with destination recommendations.
          </p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Visual Identity</p>
          <p>UX/UI Design</p>
          <p>Information Architecture</p>
          <p>Hi-fidelity Prototyping</p>
          <p>Business Analysis</p>
          <p>Graphic Design</p>
          <p>Usability Testing</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
          <img src={aeIcon} alt='after-effects-icon' width='40px'/>
          <img src={figmaIcon} alt='figma-icon' height='40px'/>
        </div>
      </div>

      <div className="project-section project-ui">
        <img src={uiSystems1} alt="ui systems"/>
        <img src={uiSystems2} alt="ui systems"/>
        <img src={uiSystems3} alt="ui systems"/>
        <img src={uiSystems4} alt="ui systems"/>
        <img src={uiSystems5} alt="ui systems"/>
        <img src={uiSystems6} alt="ui systems"/>
      </div>

      <div className="project-section end-section">
        <p>Thanh Nguyen © 2024 All Rights Reserved.</p>
      </div>

    </div>

  );

}