import React, {useState, useEffect} from 'react';
import {HashLink} from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
// import {Link} from 'react-router-dom';

import './project-detail.css';

import artTilesLogo from '../assets/arttiles/arttiles-logo.svg'
import ptsIcon from '../assets/adobe-pts-icon.svg';
import aiIcon from '../assets/adobe-illu-icon.svg';

import cover from '../assets/arttiles/cover.png';
import maHome from '../assets/arttiles/ma-home.jpg';
import maProduct from '../assets/arttiles/ma-product.jpg';
import maDetail from '../assets/arttiles/ma-detail.jpg';
import maAbout from '../assets/arttiles/ma-about.jpg';
import maContact from '../assets/arttiles/ma-contact.jpg';
import maGallery from '../assets/arttiles/ma-gallery.jpg';

export default function Arttiles() {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > '60');
      });
  }, []);

  return (
      
    <div className={`${scroll ? "project-detail-body project-arttiles-body scroll" : "project-detail-body project-arttiles-body" }`}>

      <HashLink smooth to="/arttiles/#project-landing">
        <div className='top-scroll-btn'>
          <svg width="24" height="21" viewBox="0 0 24 24">
            <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"/>
          </svg>
        </div>
      </HashLink>
      
      <Fade left>
        <div className="project-logo" id='project-landing'>
          <img src={artTilesLogo} alt='ArtTiles logo'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-cover">
          {/* <LottieAnimation lotti={imgCover}></LottieAnimation> */}
          <img src={cover} alt='ArtTiles cover'/>
        </div>
      </Fade>

      <Fade left>
        <div className="project-description">
          <p>Cement tiles, ceramic tiles are products of a more than 100 years old craft in Southern Vietnam.
            As a furniture company, M.A's (shortened for Mai Anh) mission is to keep it alive and bring the traditional
            craft into contemporary construction projects and houses. The website created for the company shows
            who we are, what we have achieved and our vision for the art of tiles.
          </p>
        </div>
      </Fade>

      <Fade left>
        <div className="project-section project-workscope">
          <h1>work scope</h1>
          <p>Website UX/UI Design</p>
        </div>
      </Fade>

      <div className="project-section project-tools">
        <h1>tools</h1>
        <div>
          <img src={ptsIcon} alt='photoshop-icon' width='40px'/>
          <img src={aiIcon} alt='illustrator-icon' width='40px'/>
        </div>
      </div>

      <div className="project-section project-mockup">
        <h1>homepage</h1>
        <img src={maHome} alt='homepage'/>
      </div>

      <div className="project-section project-mockup">
        <h1>products page</h1>
        <img src={maProduct} alt='product'/>
      </div>

      <div className="project-section project-mockup">
        <h1>product details page</h1>
        <img src={maDetail} alt='product detail'/>
      </div>

      <div className="project-section project-mockup">
        <h1>about us page</h1>
        <img src={maAbout} alt='about us'/>
      </div>

      <div className="project-section project-mockup">
        <h1>contact page</h1>
        <img src={maContact} alt='contact'/>
      </div>

      <div className="project-section project-mockup">
        <h1>gallery page</h1>
        <img src={maGallery} alt='gallery'/>
      </div>

      <div className="project-section end-section">
        <a href='https://maianharttiles.com/' target="_blank">visit our website</a>
        <p>Thanh Nguyen © 2021 All Rights Reserved.</p>
      </div>
      
    </div>

  );
  
}